import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const unique_atvs_rzrs = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'UNIQUE ATVs & RZRs | HB Privileged',
      title: 'UNIQUE ATVs & RZRs',
      subtitle: 'TOUR OVERVIEW',
      text: "If you choose this activity you can ́t forget your camera and your adventurous spirit. Let the most professional and experienced tour guides of our Bay take you through the iconic mountains of Vallarta. Go across creeks, puddles, slopes, rocks, etc. in a very safe way along miles of private trails in an authentic mexican ranch. At the end enjoy a nice dive in the river and a delicious lunch with the spectacular views that our destination has to offer. All the units that Unique uses are late model, semiautomatic, easy to drive and very potent. It doesn ́t matter how much experience you have, this tour is for the whole family, but you have to be over 16 years of age and have a valid license to drive one of the vehicles. The adventure of the quads and RZRs awaits you,",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'UNIQUE ATVs & RZRs | HB Privileged',
      title: 'UNIQUE ATVs & RZRs',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Si elige esta actividad definitivamente no puede olvidar la cámara y el espíritu aventurero. Déjese llevar por los más profesionales y expertos guías de la Bahía a través de las icónicas montañas de Vallarta. Cruce arroyos, charcos, montes, rocas, etc., de manera segura y divertida a lo largo de kilómetros de sendas privadas en un rancho auténticamente mexicano. Al final disfrute de un chapuzón en el río y de un delicioso almuerzo con las espectaculares vistas que ofrece nuestro destino. Todas la unidades que utiliza Unique son de último modelo, semiautomáticas, fáciles de manejar y muy potentes. No importa cuanta experiencia tengas este tour es para toda la familia pero se deben tener más de 16 años y una licencia válida para conducir uno de los vehículos. La aventura de las cuatrimotos y RZRs lo está esperando.',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/unique-atvs-&-rzrs1.jpg`,
    `${BASE_URL}/tours/unique-atvs-&-rzrs2.jpg`,
    `${BASE_URL}/tours/unique-atvs-&-rzrs3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/unique-atvs-&-rzrs.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default unique_atvs_rzrs;